import React from "react";
import { Header, ButtonIcon } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faUser } from "@fortawesome/free-solid-svg-icons";

import AuthContext from "../context/AuthContext";
import videoSmartGlassesMp4 from "../video/videoSmartGlasses.mp4";
import assisExpertLogo from "../images/logo-white.svg";

import axiosConfig from "../utils/axiosConfig";

export default class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }

  static contextType = AuthContext;

  handleSignOutClick() {
    let url = window.location.href
    let arr = url.split("/");
    axiosConfig({
      url: "/auth/signout",
      data: {
        url: arr[0] + "//" + arr[2]
      }
    }).then((res) => {
      if (res.data.success) {
        document.location.href = res.data.redirect;
      }
    });
  };

  render() {
    return (
      <div className="Dashboard-container">
        <div className="w-100">
          <Header height="42" logo={assisExpertLogo}>
            <ButtonIcon
              style={{ color: "#fff" }}
              onClick={() => (document.location.href = this.props.urlSettings)}
            >
              <FontAwesomeIcon icon={faUser} />
            </ButtonIcon>
            <ButtonIcon
              style={{ color: "#fff" }}
              onClick={() => this.handleSignOutClick()}
            >
              <FontAwesomeIcon icon={faPowerOff} />
            </ButtonIcon>
          </Header>
          <div className="home-background">
            <video
              style={{
                position: "relative",
                minWidth: "100%",
                minHeight: "100%",
                left: "50%",
                transform: "translateX(-50%)"
              }}
              muted
              controls={false}
              autoPlay
              preload="auto"
            >
              <source src={videoSmartGlassesMp4} type="video/mp4" />
            </video>
            <div className="home-background-overlay" />
          </div>
          <div className="Dashboard-content">
            {this.props.connected === true ? this.props.children : ""}
          </div>
        </div>
      </div>
    );
  }
}
