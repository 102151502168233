import React from 'react';

import "./button-link.css";

export default class Button extends React.Component {
  render() {
    return (
        <a
            href={this.props.href}
            target="_blank"
            className={`button-link ${this.props.className}`}
            rel="noopener noreferrer"
            style={this.props.style}
        >
            {this.props.icon ? (
              <div className="button-link-icon">
                {this.props.icon}
              </div>
            ) : null}
            <span>
              {this.props.text}
            </span>
        </a>
    )
  }
}
