import React from "react";

import "./card.css";

export default class Card extends React.Component {
  render() {
    return (
      <div className={`card ${this.props.className}`}>
            <div
                className="card-top-border"
            />
            <h2 className="card-title">{this.props.title}</h2>
            {this.props.children}
      </div>
    );
  }
}
