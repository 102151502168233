import React from "react";
import {
  FormInput,
  FormTextarea,
  Button,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faAndroid,
  faApple
} from '@fortawesome/free-brands-svg-icons';

import Card from "../../components/Card";
import ButtonLink from "../../components/ButtonLink";

import AuthContext from "../../context/AuthContext";

import axiosConfig from "../../utils/axiosConfig";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    }
  }

  static contextType = AuthContext;

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSendMessage = () => {
    axiosConfig({
      url: "/contact/send-message",
      data: {
          message: this.state.message
      }
    }).then(res => {
      if (res.data.success) {
        alert("Message envoyé avec succès");
        this.setState({ message: "" });
      } else {
        alert("Erreur lors de l'envoi du message");
      }
    })    
  }

  render() {
    return (
      <Row className="home-row">
        <Col xs={12} md={6} className="m-auto">
          <Card title="Je suis expert de terrain" className="mb-20">
            <h4 className="mt-0 mb-10">J’utilise mon smartphone :</h4>
              <Row>
                <Col xs={12} md={6}>
                  <ButtonLink
                    href="https://play.google.com/store/apps/details?id=com.DeltaCygniLabs.Pointr&hl=fr&gl=US"
                    text="Version Android"
                    icon={<FontAwesomeIcon icon={faAndroid}/>}
                    className="my-5"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <ButtonLink
                    href="https://apps.apple.com/us/app/pointr-easy-remote-support/id1073131903"
                    text="Version iOS"
                    icon={<FontAwesomeIcon icon={faApple}/>}
                    className="my-5"
                  />
                </Col>
              </Row>

              <h4 className="mt-30 mb-10">J'utilise les lunettes connectées :</h4>
              <h5 className="my-10">Réserver les lunettes :</h5>
                <FormInput
                  className="my-10"
                  type="email"
                  name="email"
                  value={this.context.email}
                  disabled
                />
                <FormTextarea 
                  className="my-10"  
                  type="text" 
                  name="message" 
                  value={this.state.message} 
                  placeholder="Votre message (optionnel)"
                  rows={3}
                  onChange={this.handleInputChange}
                />
                <Button
                  className="mt-10 mb-20"
                  text="Envoyer"
                  onClick={this.handleSendMessage}
                />

                <h5 className="mb-10">Contacter le référent :</h5>
                <ButtonLink
                  href="tel:+33695552239"
                  text="Appeler"
                />
                  
          </Card>
        </Col>
        <Col xs={12} md={6} className="m-auto">
          <Card title="Je suis expert référent" className="mb-20">
            <h4 className="mt-0 mb-10">Je prend la main à distance :</h4>
              <ButtonLink
                href="https://www.pointr.it/bin/win/setup.zip"
                text="Télécharger la version PC"
              />
          </Card>
        </Col>
      </Row>
    );
  }
}
