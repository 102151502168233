import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import routes from "./routes";

import axiosConfig from "./utils/axiosConfig";

import AuthContext from "./context/AuthContext";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: null,
      id: null,
      name: null,
      email: null,
      group: null,
      agency: null,
      urlSettings: null,
    };
  }

  componentDidMount() {
    this.checkAuth();
    this.intervalToken = setInterval(() => {
      this.checkAuth();
    }, 30 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalToken);
  }

  checkAuth = () => {
    if (!document.location.href.includes("signout")) {
      const pathArray = document.location.href.split("/")
      if (navigator.onLine) {
        let url = window.location.href
        let arr = url.split("/");
        let token = null;
        if (pathArray.includes("success")) {
          token = pathArray[pathArray.length - 1];
        }
        axiosConfig({
          url: "/auth/refresh-token",
          data: {
            token: token,
            url: arr[0] + "//" + arr[2]
          }
        }).then((res) => {
          if (res.data.success) {
            if (res.data.redirect) {
              document.location.href = res.data.redirect;
            } else {
              if (pathArray.includes("success")) {
                document.location.href = "/"
              } else {
                const oldState = this.state
                const newState = {
                  connected: true,
                  id: res.data.data.id,
                  name: res.data.data.name,
                  email: res.data.data.email,
                  group: res.data.data.group,
                  agency: res.data.data.agency,
                  urlSettings: res.data.urlSettings
                }
                if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
                  this.setState(newState)
                }
              }
            }
          } else {
            const oldState = this.state;
            const newState = {
              connected: false,
              id: null,
              name: null,
              email: null,
              group: null,
              agency: null,
              urlSettings: null,
            };
            if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
              this.setState(newState);
            }
            document.location.href = res.data.redirect;
          }
        })
      }
    } else {
      this.setState({ connected: false });
    }
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          id: this.state.id,
          name: this.state.name,
          email: this.state.email,
          group: this.state.group,
          agency: this.state.agency,
        }}
      >
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <div>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={(props) => {
                    return (
                      <route.layout
                        {...props}
                        connected={this.state.connected}
                        urlSettings={this.state.urlSettings}
                      >
                        <route.component {...props} />
                      </route.layout>
                    );
                  }}
                />
              );
            })}
          </div>
        </Router>
      </AuthContext.Provider>
    );
  }
}
