import React from "react";
import { Redirect } from "react-router-dom";

// Layouts Types
import DashboardLayout from "./layouts/Dashboard";

// Views Types
import Home from "./views/dashboard/Home";

const routes = [
  {
    path: "/",
    exact: true, 
    layout: DashboardLayout,
    component: () => <Redirect to="/home" />
  },
  {
    path: "/home",
    exact: true,
    layout: DashboardLayout,
    component: Home,
  }
]

export default routes;
